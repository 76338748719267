import React from 'react'
import { StaticQuery, graphql } from 'gatsby'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'

function SEO({ description, lang, meta, keywords, title, structuredData, url, image }) {
  return (
    <StaticQuery
      query={detailsQuery}
      render={data => {
        const metaDescription = description || data.site.siteMetadata.description
        const titleTemplate = !title.includes("UNICOM Engineering") && title.length < 40 ? title + " | " + data.site.siteMetadata.title : title
        return (
          <Helmet
          htmlAttributes={{ lang, }}
          title={title}
          titleTemplate={titleTemplate}
            meta={[
              {
                name: `title`,
                content: title,
              },
              {
                name: `description`,
                content: metaDescription,
              },
              {
                property: `og:site_name`,
                content: "UNICOM Engineering",
              },
              {
                property: `og:title`,
                content: title,
              },
              {
                property: `og:url`,
                content: url,
              },
              {
                property: `og:description`,
                content: metaDescription,
              },
              {
                property: `og:type`,
                content: `website`,
              },
              {
                property: `og:image`,
                content: image,
              },
              {
                name: `twitter:card`,
                content: `summary`,
              },
              {
                name: `twitter:creator`,
                content: data.site.siteMetadata.author,
              },
              {
                name: `twitter:title`,
                content: title,
              },
              {
                name: `twitter:description`,
                content: metaDescription,
              },
              {
                name: `twitter:site`,
                content: "@UNICOMEng",
              },
            ]

              .concat(
                keywords.length > 0
                  ? {
                    name: `keywords`,
                    content: keywords.join(`, `),
                    content: keywords,
                  }
                  : []
              )
              .concat(meta)}
          >
            {structuredData &&
            <script type="application/ld+json">
              {`
                ${JSON.stringify(structuredData, null, "\t")}
              `}            
              </script>
            }
          </Helmet>
        )
      }}
    />
  )
}

SEO.defaultProps = {
  lang: `en`,
  meta: [],
  keywords: [],
}

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.array,
  keywords: PropTypes.arrayOf(PropTypes.string),
  title: PropTypes.string.isRequired,
}

export default SEO

const detailsQuery = graphql`
  query DefaultSEOQuery {
    site {
      siteMetadata {
        title
        description
        author
        siteUrl
      }
    }
  }
`
